<template>
  <div class="home pt-5">
    <v-container style="max-width:940px" class="py-0">
        <v-row no-gutters >
          <v-col cols="12">
            <div class="bg-image padding-image-top" :style="'background-image: url('+require('@/assets/style/palo_inizio.png')+')'">
              <div class="light more-radius-top">
                <v-img class="mx-auto logo" contain :src="require('@/assets/logo.png')"></v-img>
              </div>
            </div>
          </v-col>
          <v-col class="col-12">
            <div>
              <div class="bg-image padding-image" :style="'background-image: url('+require('@/assets/style/palo_centro.png')+');'">
                <div class="light">
                <v-row align="center" justify="center" class="fill-height">
                  <v-col cols="12" class="text-center" style="margin-top: 0px">
                    <h1 class="primary--text">{{$t('pages.home.header.title')}}</h1>
                    <p>{{$t('pages.home.header.sub_title')}}</p>
                  </v-col>
                </v-row>
                </div>
              </div>
            </div>
          </v-col>
          <v-col class="col-12">
            <div class="bg-image padding-image" :style="'background-image: url('+require('@/assets/style/palo_centro.png')+'); padding-top:150px;'">
              <div class="primary">
              <div class="semicirle-top"></div>
              <v-row align="center" justify="center" class="fill-height text-center">
                <v-col cols="12" class="mt-5" self-align="start">
                  <h1 class="secondary--text">{{$t('pages.home.section.title')}}</h1>
                </v-col>
                <v-col cols="9">
                  <v-card width="33%" class="float-start " style="z-index:2; transform: rotate(-20deg);">
                    <v-img :src="require('@/assets/mask-preview/card-1.jpg')"></v-img >
                  </v-card>
                  <v-card width="33%" class="float-start wobble-hor-bottom" style="z-index:3">
                    <v-img :src="require('@/assets/mask-preview/card-3.jpg')"></v-img >
                  </v-card>
                  <v-card width="33%" class="float-start " style="z-index:1; transform: rotate(20deg);">
                    <v-img :src="require('@/assets/mask-preview/card-2.jpg')"></v-img >
                  </v-card>
                </v-col>
                <v-col cols="12 py-5">
                  <p class="secondary--text">{{$t('pages.home.section.text')}}</p>
                </v-col>
              </v-row>
              <div class="semicirle-bottom"></div>
            </div>
            </div>
          </v-col>
           <v-col class="col-12">
              <div class="bg-image fill-height padding-image" :style="'background-image: url('+require('@/assets/style/palo_centro.png')+')'">
              <div class="light">
                <v-row align="center" justify="center" class="fill-height text-center my-0 py-0">
                <v-col cols="12" self-align="start" style="margin-top:-80px">
                  <p>{{$t('pages.home.section.premask')}}</p>
                </v-col>
                <v-col cols="11" class="my-5" >
                  <v-row align="center" justify="center">
                    <v-col v-for="(column, index) in masks" :key="'mask'+index" cols="10" md="4">
                      <div v-for="mask in shuffle(column)" :key="mask" class="pb-5">
                        <v-card :to="{name:'camera',params:{'mask':mask}}" elevation="5">
                          <v-img :src="require('@/assets/mask/'+mask+'.jpg')"></v-img >
                        </v-card>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                </v-row>
              </div>
            </div>
           </v-col>
        </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data: () => ({
      masks: [
        ['mask-1','mask-2','mask-3'],
        ['mask-4','mask-5','mask-6','mask-7'],
        ['mask-8','mask-9','mask-10'],
      ],
  }),
  mounted(){

  },
  methods:{
     shuffle(array) {
        let currentIndex = array.length,  randomIndex;

        // While there remain elements to shuffle...
        while (currentIndex != 0) {

          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;

          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }

        return array;
      }
  }
}
</script>
<style scoped>
.logo{
  max-width: 100px;
  top: -50px;
  position: relative;
}
.padding-image-top{
    padding-top: 12%;
    padding-left: 12%;
    padding-right: 12%;
}
.padding-image{
    padding-left: 12%;
    padding-right: 12%;
}
.more-radius-top{
  border-radius: 15px 15px 0px 0px;
}
.semicirle-top{
  background-color: #FFFFFF;
  border-radius: 0px 0px 100% 100% ;
  height: 150px;
  margin-top: -150px;
}
.semicirle-bottom{
  background-color: #FFFFFF;
  border-radius:  100% 100% 0px 0px;
  height: 150px;
}

.wobble-hor-bottom {
  -webkit-animation: wobble-hor-bottom 1.8s ease-out infinite both;
  animation: wobble-hor-bottom 1.8s ease-out infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-12-1 12:5:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation wobble-hor-bottom
 * ----------------------------------------
 */
@-webkit-keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
            transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
            transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
            transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
            transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
            transform: translateX(-6px) rotate(-1.2deg);
  }
}
@keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
            transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
            transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
            transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
            transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
            transform: translateX(-6px) rotate(-1.2deg);
  }
}

</style>